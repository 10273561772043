import { useRef } from 'react';
import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { PopupButton } from '@typeform/embed-react';
import { Wrap } from '../components/sections-wrap';
import { removingWordFree } from '../helpers';
import {
  AttorneyCalculateCard,
  InfoCareerTabs,
  AttorneyCalculateCardMobile,
  Benefits,
  Stories,
  Disclaimer,
} from '../components/pages/careers';
import { Breadcrumb } from '../components/common/breadcrumb/breadcrumb';
import * as styles from './templates.module.scss';
import { constants } from '../config';

function CareerDetailTemplate({ data }) {
  const { careersSettings, careerPage } = data;
  const {
    title, datePublished, payCalculator, content, location,
  } = careerPage;
  const { benefits, stories, disclaimer } = careersSettings || {};

  const { sizeTypeFormPopUp } = constants;

  return (
    <div className={styles.careerPageDetail}>
      <Breadcrumb text={`Careers > ${title}`} to="/about/careers" />
      <Wrap>
        <div className={styles.contentContainer}>
          {payCalculator === 'Attorney' && <AttorneyCalculateCard />}
          <div className={styles.leftSideContent}>
            <div className={styles.titleContainer}>
              <span className={styles.title}>{title}</span>
              <span className={styles.date}>{`Posted ${datePublished}`}</span>
            </div>
            <InfoCareerTabs
              data={careerPage}
              iconsStyle={styles.tabIcons}
              tabBackground={styles.tabBackground}
            />
            <hr className={styles.yellowLine} />
            {payCalculator === 'Attorney' && <AttorneyCalculateCardMobile />}
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(
                  JSON.parse(removingWordFree(content.raw)),
                ),
              }}
            />
            <PopupButton
              id={`${process.env.GATSBY_APPLY_FORM_ID}#career_position=${title}`}
              hidden={{ location: location?.slug }}
              className={styles.apply}
              size={sizeTypeFormPopUp}
            >
              Apply Now
            </PopupButton>
          </div>
        </div>
      </Wrap>
      <Benefits data={benefits} myRef={useRef(null)} />
      <Stories data={stories} />
      <Disclaimer content={disclaimer?.disclaimer} />
    </div>
  );
}

export const query = graphql`
  query ($uri: String) {
    careerPage: contentfulPageCareersDetail(uri: { eq: $uri }) {
      title
      datePublished(formatString: "MM/DD/YYYY")
      location {
        locationTitle
        slug
        addressRegion
      }
      employmentType
      salaryArrangement
      minimumSalary
      maximumSalary
      employmentType
      payCalculator
      content {
        raw
      }
    }
    careersSettings: contentfulPageCareersSettings(
      id: { eq: "cd0a78c4-a261-588b-8db8-61c00b84adb7" }
    ) {
      benefits {
        id
        title
        image {
          gatsbyImage(width: 260)
          description
        }
        description {
          description
        }
      }
      stories {
        id
        title
        video {
          title
          file {
            url
          }
        }
        description {
          description
        }
        attorney {
          title
          uri
          slug
        }
      }
      disclaimer {
        disclaimer
      }
      seo {
        pageTitle
        metaDescription
      }
    }
  }
`;

CareerDetailTemplate.propTypes = {
  data: object,
};

CareerDetailTemplate.defaultProps = {
  data: {},
};

export default CareerDetailTemplate;
export { Head } from '../components/seo/seo';

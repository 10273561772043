import { Link } from 'gatsby';
import { string } from 'prop-types';
import * as styles from './breadcrumb.module.scss';

function Breadcrumb({ text, to }) {
  return (
    <div className={styles.extendedWrap}>
      <Link className={styles.goBack} to={to}>{text}</Link>
    </div>
  );
}

Breadcrumb.propTypes = {
  text: string.isRequired,
  to: string.isRequired,
};

export { Breadcrumb };
